import { AxiosResponse } from "axios";
import { requests } from "./";
import {
  IBannerPromotions,
  IPromotionsList,
  IPromotionsWorkshopList,
  IPromotion,
  IPromotionsByWorkshop,
} from "../../models";

const PromotionsAPI = {
  
  getWorkshopsByPromotions: (
    promotionId: string,
    params?: {
      page?: number;
      pageSize?: number;
      coordinates?: string;
      sortDesc?: boolean;
      sortCriteria?: "rating" | "distance" | "price" | "relevance";
    }
  ): Promise<AxiosResponse<Array<IPromotionsWorkshopList>>> =>
    requests.get(`/promotions/${promotionId}/workshops`, params),
  getPromotionsList: (params: {
    page?: number;
    pageSize?: number;
    sortDesc?: boolean;
    sortCriteria?: "date" | "discount";
    filterCriteria?: string;
  }): Promise<AxiosResponse<Array<IPromotionsList>>> =>
    requests.get(`/promotions`, params),
  getPromotion: (promotionId: string): Promise<AxiosResponse<IPromotion>> =>
    requests.get(`/promotions/${promotionId}`),
  getPromotionByWorkshop: (
    workshopId: string
  ): Promise<AxiosResponse<IPromotionsByWorkshop>> =>
    requests.get(`/promotions/workshop/${workshopId}`),
  /**
   * Unused code but relevant for the project
   */
  /*
  getPromotions: (): Promise<AxiosResponse<Array<IBannerPromotions>>> =>
    requests.get(`/promotions/promotionbanners`),
  */
};

export default PromotionsAPI;