import { AxiosResponse } from "axios";
import { requests } from "./";
import {
  IBlogEntry,
  IBlogCategory,
  IBlogTag,
  IBlogAuthor,
  TEntryStatus,
  TEntryAudience
} from "../../models/blog";

const BlogAPI = {
  getPosts: async ({
    page = 0,
    pageSize = 15,
    audience = "ALL",
    statusCodes = ["visible"],
    showContent = false,
    EntryIdOffset,
    pinnedContent,
    urlCategory,
    urlTag,
    urlAuthor
  }:{
    page: number;
    pageSize: number;
    audience: TEntryAudience;
    statusCodes: TEntryStatus[];
    showContent: boolean; // if is set to false, contentFragments will not be loaded
    EntryIdOffset?: number;
    pinnedContent?: boolean;
    urlCategory?: string;
    urlTag?: string;
    urlAuthor?: string;
  }) : Promise<AxiosResponse<{
    entries: IBlogEntry[];
    pages: number;
    totalEntries: number;
  }>>  => {
    const payload = {
      page,
      pageSize,
      audience,
      statusCodes: statusCodes.join(","),
      showContent,
      ...(EntryIdOffset ? {EntryIdOffset} : {}),
      ...(pinnedContent ? {pinnedContent} : {})
    }
    if (urlCategory) return requests.get(`blog/category/${urlCategory}/entries`, payload);
    if (urlTag) return requests.get(`blog/tag/${urlTag}/entries`, payload);
    if (urlAuthor) return requests.get(`blog/author/${urlAuthor}/entries`, payload);
    return requests.get(`/blog/entry`, payload);
  },
  getPostBy: async ({
    id,
    urlTitle
  }:{
    id?: number;
    urlTitle?: string;
  }) :Promise<AxiosResponse<IBlogEntry>> => requests.get(`/blog/entry/${id || urlTitle}`),
  setPost: async (payload: IBlogEntry) :Promise<AxiosResponse<IBlogEntry>> => {
    if (typeof payload.contentFragments !== "string") payload.contentFragments = JSON.stringify(payload.contentFragments);
    return requests.post(`/blog/entry`, payload);
  },
  updatePost: async ({
    id,
    payload
  }:{
    id: number;
    payload: IBlogEntry
  }) :Promise<AxiosResponse<IBlogEntry>> => {
    if (typeof payload.contentFragments !== "string") payload.contentFragments = JSON.stringify(payload.contentFragments);
    return requests.put(`/blog/entry/${id}`, payload)
  },
  getTags: async () :Promise<AxiosResponse<IBlogTag[]>> => requests.get(`/blog/tag`),
  getTagBy: async ({
    id,
    urlName
  }:{
    id?: number;
    urlName?: string;
  }) :Promise<AxiosResponse<IBlogTag>> => requests.get(`/blog/tag/${id || urlName}`),
  setTag: async (payload : {
    tagName: string;
    urlName: string;
  }) :Promise<AxiosResponse<IBlogTag>> => requests.post(`/blog/tag`, payload),
  updateTag: async ({
    id,
    payload
  }:{
    id: number;
    payload: {
      tagName: string;
      urlName: string;
    }
  }) :Promise<AxiosResponse<IBlogTag>> => requests.put(`/blog/tag/${id}`, payload),

  getAuthors: async () :Promise<AxiosResponse<IBlogAuthor[]>> => requests.get(`/blog/author`),
  getAuthorBy: async (id: number) :Promise<AxiosResponse<IBlogAuthor>> => requests.get(`/blog/author/${id}`),
  setAuthor: async (payload : {
    authorName: string;
    urlName: string;
  }) :Promise<AxiosResponse<IBlogAuthor>> => requests.post(`/blog/author`, payload),
  updateAuthor: async ({
    id,
    payload
  }:{
    id: number;
    payload: {
      authorName: string;
      urlName: string;
    }
  }) :Promise<AxiosResponse<IBlogAuthor>> => requests.put(`/blog/author/${id}`, payload),

  getCategories: async () :Promise<AxiosResponse<IBlogCategory[]>> => requests.get(`/blog/category`),
  getCategoryBy: async (id: number) :Promise<AxiosResponse<IBlogCategory>> => requests.get(`/blog/category/${id}`),
  setCategory: async (payload : {
    categoryName: string;
    urlName: string;
  }) :Promise<AxiosResponse<IBlogCategory>> => requests.post(`/blog/category`, payload),
  updateCategory: async ({
    id,
    payload
  }:{
    id: number;
    payload: {
      categoryName: string;
      urlName: string;
    }
  }) :Promise<AxiosResponse<IBlogCategory>> => requests.put(`/blog/category/${id}`, payload),
}

export default BlogAPI;
