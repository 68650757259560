import { AxiosResponse } from "axios";
import { requests } from "./";
import { Appointment } from "../../models";

const AppointmentAPI = {
  get: async (
    startDateTime: string,
    endDateTime: string,
    workshopId?: number
  ): Promise<AxiosResponse<Appointment>> =>
    requests.get(
      `/workshopappointments?dateFrom=${startDateTime}&dateTo=${endDateTime}${
        workshopId ? "&workshopId=" + workshopId : ""
      }`
    ),
  getEC: async (
    startDateTime: string,
    endDateTime: string,
    userId?: number
  ): Promise<AxiosResponse<Appointment>> =>
    requests.get(
      `/userappointments?dateFrom=${startDateTime}&dateTo=${endDateTime}${
        userId ? "&userId=" + userId : ""
      }`
    ),
  add: async (
    appointment: Appointment,
    workshopId: string,
    isReschedule?: boolean
  ): Promise<AxiosResponse<Appointment>> =>
    requests.post(
      `/workshops/${workshopId}/appointments${
        isReschedule ? "?isReschedule=true" : ""
      }`,
      appointment
    ),
  addNonRegistered: async (
    appointment: Appointment,
    workshopId: string,
    localTimeDifference: string,
    isReschedule?: boolean,
    coordinates?: string
  ): Promise<AxiosResponse<Appointment>> =>
    requests.post(
      `/workshops/${workshopId}/appointmentsNonRegistered?localTimeDifference=${localTimeDifference}${
        isReschedule ? "&isReschedule=true" : ""
      }&coordinates=${coordinates ?? ""}`,
      appointment
    ),
  update: async (
    appointment: Appointment,
    workshopId: string,
    localTimeDifference: string,
    isReschedule?: boolean
  ): Promise<AxiosResponse<Appointment>> =>
    requests.put(
      `/workshops/${workshopId}/appointments/${
        appointment.id
      }?localTimeDifference=${localTimeDifference}${
        isReschedule ? "&isReschedule=true" : ""
      }`,
      appointment
    ),
  confirm: async (id: string, code: string): Promise<AxiosResponse<any>> =>
    requests.put(
      `/workshops/appointmentsNonRegistered/${id}/?validationCode=${code}`,
      `"${code}"`,
      { headers: { "Content-Type": "application/json" } }
    ),
  getTrackingReception: async (id: string): Promise<AxiosResponse<any>> =>
    requests.get(`/appointmentTrackingReception/${id}`),
  getAppById: async (appId: string): Promise<AxiosResponse<any>> =>
    requests.get(`/workshops/appointments/${appId}`),
  getAppByCode: async (appCode: string): Promise<AxiosResponse<any>> =>
    requests.get(`/appointmentByIdentifier/${appCode}`),
  getLastAppointment: async (userId: string): Promise<AxiosResponse<any>> =>
    requests.get(`/lastAppointment/${userId}`),
  approveQuotation: async (payload: any): Promise<AxiosResponse<any>> =>
    requests.post(`/Quotation/ApproveQuotationForEndConsumer`, payload),
  changeAppointmentHiddenStatus: (
    workshopAppointmentId: string,
    hidden: boolean
  ): Promise<AxiosResponse<any>> =>
    requests.put(
      `/workshops/appointments/${workshopAppointmentId}/Hidden?IsHidden=${hidden}`,
      `${hidden}`
    ),
  deleteAppointment: (
    workshopAppointmentId: string
  ): Promise<AxiosResponse<any>> =>
    requests.del(`/workshops/appointments/${workshopAppointmentId}`),
};

export default AppointmentAPI;
